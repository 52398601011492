import Service from '../Service';

const resource = "chamber/"

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    save(cha, requestID) {
        return Service.post(resource + 'save', cha, {
            params: { requestID }
        });
    },

    list(requestID) {
        return Service.post(resource + 'list', {}, {
            params: { requestID }
        });
    },

    listTemperatureZitrap(ChaID, requestID) {
        return Service.post(resource + 'listTemperatureZitrap', {}, {
            params: { ChaID, requestID }
        });
    },

    listChamberZitrap(requestID) {
        return Service.post(resource + 'listChamberZitrap', {}, {
            params: { requestID }
        });
    },

    listChamberPosition(obj, requestID) {
        return Service.post(resource + 'listChamberPosition', obj, {
            params: { requestID }
        });
    },
}