import Service from '../Service';

const resource = "chamber/"

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "paginationPosition", parameters, {
            params: { requestID },
        });
    },

    save(cha, requestID) {
        return Service.post(resource + 'savePosition', cha, {
            params: { requestID }
        });
    },
}