<template>
	<div>
		<v-container>
			<s-crud
				:filter="filter"
				:config="configChamber"
				add
				edit
				remove
				@save="save($event)"
				title="Cámaras"
				height="auto"
			>
				<template scope="props">
					<v-col>
						<v-row>
							<v-col cols="6">
								<s-text
									v-model="props.item.ChaDescription"
									label="Descripción"
								></s-text>
							</v-col>
							<v-col cols="2">
								<s-select-definition
									:def="1291"
									label="Destino"
									v-model="props.item.TypeFreshProductionDestination"
								/>
							</v-col>
							<!-- <v-col cols="3">
								<s-select
									clearable
									:items="itemsChamberSitrap"
									item-value="ChaID"
									item-text="ChaDescription"
									label="Tunel Sitrap"
									v-model="props.item.SitrapID"
								/>
							</v-col> -->
							<v-col cols="2">
								<s-select-definition
									:def="1347"
									label="Tipo"
									v-model="
										props.item
											.TypeChamberTunnel
									"
								/>
							</v-col>
							<v-col cols="2">
								<s-select-definition
									:def="1282"
									label="Estado"
									v-model="props.item.ChaStatus"
								/>
							</v-col>
						</v-row>
					</v-col>
				</template>

				<template v-slot:Positions="{ row }">
					<v-btn
						color="success"
						x-small
						rounded
						@click="clickPositions(row)"
					>
						+</v-btn
					>
				</template>
			</s-crud>

			<v-dialog
				v-model="openModal"
				v-if="openModal"
				width="700"
				persistent
			>
				<v-card>
					<v-container>
						<v-row>
							<h3 class="mt-3 ml-6">
								Posiciones - {{ itemSelected.ChaDescription }}-
								<!-- {{ itemSitrap.ChaDescription }} /
								{{ itemSitrap.ChaID }} -->
							</h3>
							<v-spacer> </v-spacer>
							<v-btn
								@click="openModal = false"
								class="mt-3 mr-3"
								small
								fab
								color="error"
								><i
									style="font-size: 16px"
									class="fas fa-times"
								></i
							></v-btn>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-divider></v-divider>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<s-crud
									:filter="filterChamberPos"
									:config="configChamberPos"
									add
									edit
									remove
									@save="savePosition($event)"
									noFooter
									noSearch
								>
									<template scope="props">
										<v-row style="margin:auto">
											<v-col lg="8" cols="12" class="pt-0">
												<s-text
													v-model="
														props.item.ChpDescription
													"
													label="Descripción"
												></s-text>
											</v-col>
											<v-col lg="4" cols="12" class="pt-0">
												<s-text
													v-model="
														props.item.ChpPosition
													"
													number
													:min="0"
													label="Posición"
												></s-text>
											</v-col>
										</v-row>
									</template>
								</s-crud>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-dialog>
		</v-container>
	</div>
</template>

<script>
	import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";
	import _sPrfChamberPositionService from "@/services/FreshProduction/PrfChamberPositionService";

	export default {
		data() {
			return {
				filter: {},
				filterChamberPos: {},
				configChamberPos: {
					model: {
						ChpID: "ID",
						Positions: "",
					},
					service: _sPrfChamberPositionService,
					headers: [
						{ text: "Descripción", value: "ChpDescription" },
						{ text: "Posicion", value: "ChpPosition" },
					],
				},
				configChamber: {
					model: {
						ChaID: "ID",
						Positions: "",
					},
					service: _sPrfChamberService,
					headers: [
						{ text: "Posiciones", value: "Positions", width: 20 },
						{ text: "ID", value: "ChaID" },
						{ text: "Descripción", value: "ChaDescription" },
						{
							text: "Destino",
							value: "TypeFreshProductionDestinationText",
						},
						{
							text: "Tipo",
							value: "TypeChamberTunnelName",
						},
						{ text: "Estado", value: "ChaStatusText" },
					],
				},
				itemsChamberSitrap: [],
				itemSelected: {},
				itemSitrap: {},
				openModal: false,
				itemchamberPosition: {},
			};
		},

		created() {
			/* _sPrfChamberService
				.listChamberZitrap(this.$fun.getUserID())
				.then((resp) => {
					if (resp.status == 200) {
						this.itemsChamberSitrap = resp.data;
						console.log("itemsChamberSitrap", this.itemsChamberSitrap);
					}
				}); */

			//this.filterChamberPos.ChaID = 1
		},

		methods: {
			save(item) {
				console.log("Guardar ", item);
				item.save();
			},

			savePosition(val) {
				
				if(val.ChpDescription.length == 0){
					this.$fun.alert("Digite una Descripción", "warning")
					return;
				}

				if(val.ChpPosition <= 0){
					this.$fun.alert("Digite una Posición", "warning")
					return;
				}
				val.ChpDescription = val.ChpDescription.trim();
				val.ChaID  = this.itemSelected.ChaID
				val.SecStatus = 1
				val.UsrCreateID = this.$fun.getUserID()
				console.log("Guardar posiciones", val);
				val.save()
			},

			clickPositions(item) {
				console.log(item);
				this.itemSelected = item;

				if (item.SitrapID !== null) {
					this.itemSitrap = this.itemsChamberSitrap.find(
						(x) => x.ChaID == item.SitrapID
					);
				} else {
					this.itemSitrap = {};
				}

				this.filterChamberPos.ChaID = item.ChaID

				console.log("itemmmmmmmmmm", this.itemSelected);
				this.openModal = true;
			},
		},
	};
</script>