var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('s-crud',{attrs:{"filter":_vm.filter,"config":_vm.configChamber,"add":"","edit":"","remove":"","title":"Cámaras","height":"auto"},on:{"save":function($event){return _vm.save($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('s-text',{attrs:{"label":"Descripción"},model:{value:(props.item.ChaDescription),callback:function ($$v) {_vm.$set(props.item, "ChaDescription", $$v)},expression:"props.item.ChaDescription"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('s-select-definition',{attrs:{"def":1291,"label":"Destino"},model:{value:(props.item.TypeFreshProductionDestination),callback:function ($$v) {_vm.$set(props.item, "TypeFreshProductionDestination", $$v)},expression:"props.item.TypeFreshProductionDestination"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('s-select-definition',{attrs:{"def":1347,"label":"Tipo"},model:{value:(
									props.item
										.TypeChamberTunnel
								),callback:function ($$v) {_vm.$set(props.item
										, "TypeChamberTunnel", $$v)},expression:"\n\t\t\t\t\t\t\t\t\tprops.item\n\t\t\t\t\t\t\t\t\t\t.TypeChamberTunnel\n\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('s-select-definition',{attrs:{"def":1282,"label":"Estado"},model:{value:(props.item.ChaStatus),callback:function ($$v) {_vm.$set(props.item, "ChaStatus", $$v)},expression:"props.item.ChaStatus"}})],1)],1)],1)]}},{key:"Positions",fn:function({ row }){return [_c('v-btn',{attrs:{"color":"success","x-small":"","rounded":""},on:{"click":function($event){return _vm.clickPositions(row)}}},[_vm._v(" +")])]}}])}),(_vm.openModal)?_c('v-dialog',{attrs:{"width":"700","persistent":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('h3',{staticClass:"mt-3 ml-6"},[_vm._v(" Posiciones - "+_vm._s(_vm.itemSelected.ChaDescription)+"- ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 mr-3",attrs:{"small":"","fab":"","color":"error"},on:{"click":function($event){_vm.openModal = false}}},[_c('i',{staticClass:"fas fa-times",staticStyle:{"font-size":"16px"}})])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('s-crud',{attrs:{"filter":_vm.filterChamberPos,"config":_vm.configChamberPos,"add":"","edit":"","remove":"","noFooter":"","noSearch":""},on:{"save":function($event){return _vm.savePosition($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{staticStyle:{"margin":"auto"}},[_c('v-col',{staticClass:"pt-0",attrs:{"lg":"8","cols":"12"}},[_c('s-text',{attrs:{"label":"Descripción"},model:{value:(
													props.item.ChpDescription
												),callback:function ($$v) {_vm.$set(props.item, "ChpDescription", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tprops.item.ChpDescription\n\t\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"4","cols":"12"}},[_c('s-text',{attrs:{"number":"","min":0,"label":"Posición"},model:{value:(
													props.item.ChpPosition
												),callback:function ($$v) {_vm.$set(props.item, "ChpPosition", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tprops.item.ChpPosition\n\t\t\t\t\t\t\t\t\t\t\t\t"}})],1)],1)]}}],null,false,494688908)})],1)],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }